<template>
  <section class="advantages">
    <div class="wrapper">
      <h2 class="visually-hidden">Наши преимущества</h2>
      <ul class="advantages_list" v-if="content.list">
        <li
          class="advantages_item"
          v-for="(item, index) in content.list"
          v-bind:key="index"
          data-aos="custom-appearance-1"
          :data-aos-delay="100 + index * 150"
        >
          <div class="advantages_item-wrapp-img" v-if="item.id_img">
            <img
              class="advantages_item-img"
              v-bind:src="getImagesUrl(item.id_img)"
              alt=""
            />
          </div>
          <div class="advantages_item-wrapp-content">
            <h3 class="advantages_item-title" v-if="item.title">
              {{ item.title }}
            </h3>
            <p class="advantages_item-desc" v-if="item.desc">
              {{ item.desc }}
            </p>
          </div>
        </li>
      </ul>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      content: this.$store.state.data.contentResponse.advantages,
      media: this.$store.state.data.mediaResponse,
    };
  },
  methods: {
    getImagesUrl(id) {
      return this.media[id].full.source_url || "";
    },
  },
};
</script>

<style>
.advantages {
  padding: 150px 0;
  background-color: #f7f7f7;
}

.advantages_list {
  padding: 0;
  margin: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  list-style: none;
}

.advantages_item {
  width: 330px;
  margin-right: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
}

.advantages_item:last-child {
  margin-right: 0;
  margin-bottom: 0;
}

.advantages_item-wrapp-img {
  height: 78px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 30px;
}

.advantages_item-img {
  display: block;
}

.advantages_item-title {
  margin: 0;
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: 700;
  line-height: 1.25;
  color: #323232;
}

.advantages_item-desc {
  margin: 0;
  line-height: 1.25;
}

@media screen and (max-width: 1599px) {
  .advantages {
    padding: 120px 0;
  }

  .advantages_item-wrapp-img {
    height: 55px;
    margin-bottom: 25px;
  }

  .advantages_item-img {
    height: 100%;
  }

  .advantages_item-title {
    margin-bottom: 15px;
    font-size: 14px;
  }

  .advantages_item-desc {
    font-size: 14px;
  }
}

@media screen and (max-width: 1199px) {
  .advantages {
    padding: 100px 0;
  }

  .advantages_item {
    width: 220px;
  }

  .advantages_item-wrapp-img {
    height: 49px;
    margin-bottom: 20px;
  }

  .advantages_item-title {
    margin: 0;
    margin-bottom: 10px;
    font-size: 12px;
  }

  .advantages_item-desc {
    font-size: 12px;
  }
}

@media screen and (max-width: 991px) {
  .advantages {
    padding: 90px 0;
  }

  .advantages_list {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  .advantages_item {
    width: 255px;
    margin-right: 50px;
    margin-bottom: 45px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    text-align: left;
  }

  .advantages_item:nth-child(2n) {
    margin-right: 0;
  }

  .advantages_item:nth-last-child(-n + 2) {
    margin-bottom: 0;
  }

  .advantages_item-wrapp-img {
    width: 56px;
    padding-right: 21px;
    margin: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
  }

  .advantages_item-img {
    width: 100%;
    height: auto;
  }

  .advantages_item-title {
    margin-bottom: 10px;
    font-size: 12px;
  }

  .advantages_item-desc {
    font-size: 12px;
  }
}

@media screen and (max-width: 767px) {
  .advantages {
    padding: 80px 0;
  }

  .advantages_list {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .advantages_item {
    margin-right: 0;
  }

  .advantages_item:nth-last-child(-n + 2) {
    margin-bottom: 45px;
  }

  .advantages_item:last-child {
    margin-bottom: 0;
  }
}
</style>