<template>
  <section id="about" class="about">
    <div class="wrapper about_wrapper">
      <div class="about_content" data-aos="fade-up" data-aos-delay="200">
        <h2 class="about_title" v-if="content.title">
          {{ content.title }}
        </h2>
        <div
          class="user-content user-content_about"
          v-if="content.desc"
          v-html="content.desc"
        ></div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      content: this.$store.state.data.contentResponse.about,
    };
  },
};
</script>
<style scoped>
.about {
  padding: 150px 0 130px 0;
  position: relative;
  z-index: 0;
  background-color: #2b2a62;
  background-image: url(@/assets/img/about_bg.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.about::before {
  content: "";
  width: calc(50vw + 135px);
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: -1;
  background-color: rgba(43, 42, 98, 0.85);
}

.about_content {
  width: 570px;
  margin-left: auto;
  margin-right: 130px;
}

.about_title {
  margin: 0;
  margin-bottom: 60px;
  font-size: 48px;
  font-weight: 700;
  line-height: 1.5;
  color: #ffffff;
}

.user-content_about {
  color: #ffffff;
}

.user-content_about p {
  margin: 0;
  margin-bottom: 30px;
}

@media screen and (max-width: 1599px) {
  .about {
    padding: 120px 0;
    background-image: url(@/assets/img/about_bg_1599.jpg);
  }

  .about_content {
    width: 520px;
    margin-right: 15px;
  }

  .about_title {
    margin-bottom: 50px;
    font-size: 40px;
  }

  .user-content_about p {
    margin: 0;
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 1199px) {
  .about {
    padding: 100px 0;
    background-image: url(@/assets/img/about_bg_1199.jpg);
  }

  .about::before {
    width: calc(50vw + 91px);
  }

  .about_content {
    width: 385px;
    margin-right: 80px;
  }

  .about_title {
    margin-bottom: 45px;
    font-size: 32px;
  }

  .user-content_about p {
    margin: 0;
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 991px) {
  .about {
    padding: 90px 0;
    background-image: url(@/assets/img/about_bg_991.jpg);
  }

  .about_content {
    width: 285px;
    margin-right: 0;
  }

  .about_title {
    font-size: 28px;
  }
}

@media screen and (max-width: 767px) {
  .about {
    padding: 80px 0 280px 0;
    background-image: url(@/assets/img/about_bg_767.jpg);
    background-size: 100% 200px;
    background-position: bottom;
  }

  .about::before {
    display: none;
  }

  .about_content {
    width: 95%;
    margin-right: 0;
  }

  .about_title {
    font-size: 26px;
  }
}
</style>