<template>
  <section id="payment-delivery" class="payment-delivery">
    <div class="wrapper payment-delivery_wrapper">
      <div
        class="payment-delivery_img-wrapper"
        v-if="content.id_img"
        data-aos="fade-up"
        data-aos-delay="200"
      >
      <a data-fancybox="video" v-bind:href="content.video">
        <span class="payment-delivery_play"></span>
        <picture>
          <source
            v-bind:srcset="getImagesUrl(content.id_img, 'paymentDelivery991')"
            media="(max-width: 991px)"
          />
          <source
            v-bind:srcset="getImagesUrl(content.id_img, 'paymentDelivery1199')"
            media="(max-width: 1199px)"
          />
          <source
            v-bind:srcset="getImagesUrl(content.id_img, 'paymentDelivery1599')"
            media="(max-width: 1599px)"
          />
          <img
            class="payment-delivery_img"
            v-bind:src="getImagesUrl(content.id_img, 'paymentDelivery1920')"
            alt=""
          />
        </picture>
      </a>
      </div>
      <div
        class="payment-delivery_content"
        data-aos="fade-up"
        data-aos-delay="500"
      >
        <h2 class="payment-delivery_title" v-if="content.title">
          {{ content.title }}
        </h2>
        <div
          class="user-content user-content_payment-delivery"
          v-html="content.desc"
          v-if="content.desc"
        ></div>
      </div>
    </div>
  </section>
</template>

<script>
  import { Fancybox } from "@fancyapps/ui";
  import "@fancyapps/ui/dist/fancybox.css";
 
  export default {
    data() {
      return {
        content: this.$store.state.data.contentResponse.delivery_payment,
        media: this.$store.state.data.mediaResponse,
      };
    },
    methods: {
      getImagesUrl(id, size) {
        return (
          this.media[id][size] ? this.media[id][size].source_url : this.media[id].full.source_url
        );
      },
    },
    mounted() {
      Fancybox.bind("data-fancybox='video'");
    }
  };
</script>

<style scoped>
.payment-delivery {
  padding: 79px 0;
  background-color: #2b2a62;
  background-image: -webkit-gradient(
      linear,
      left bottom,
      left top,
      from(rgba(43, 42, 98, 0.85)),
      to(rgba(43, 42, 98, 0.85))
    ),
    url(@/assets/img/payment-delivery_bg.jpg);
  background-image: -o-linear-gradient(
      bottom,
      rgba(43, 42, 98, 0.85),
      rgba(43, 42, 98, 0.85)
    ),
    url(@/assets/img/payment-delivery_bg.jpg);
  background-image: linear-gradient(
      0deg,
      rgba(43, 42, 98, 0.85),
      rgba(43, 42, 98, 0.85)
    ),
    url(@/assets/img/payment-delivery_bg.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.payment-delivery_wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.payment-delivery_img-wrapper {
  position: relative;
  -ms-flex-preferred-size: 50%;
  flex-basis: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  cursor: pointer;
}

.payment-delivery_play {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50px;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  margin: auto;
  background-color: rgba(255, 255, 255, 0.8);
  transition: transform 0.4s ease;
  box-shadow: 0 0 0 20px rgba(255, 255, 255, 0.3);
 }

.payment-delivery_play:hover {
  transform: scale(1.1);
}

.payment-delivery_play::before {
  content: '';
  display: block;
  width: 13px;
  height: 20px;
  background-image: url('@/assets/img/play.svg');
  background-repeat: no-repeat;
  background-position: center;

}

.payment-delivery_img {
  display: block;
}

.payment-delivery_content {
  max-width: 560px;
  padding-left: 15px;
  -ms-flex-preferred-size: 50%;
  flex-basis: 50%;
}

.payment-delivery_title {
  margin: 0;
  margin-bottom: 60px;
  font-weight: 700;
  font-size: 48px;
  line-height: 1.5;
  color: #ffffff;
}

.user-content_payment-delivery {
  color: #ffffff;
}

@media screen and (max-width: 1599px) {
  .payment-delivery {
    background-image: -webkit-gradient(
        linear,
        left bottom,
        left top,
        from(rgba(43, 42, 98, 0.85)),
        to(rgba(43, 42, 98, 0.85))
      ),
      url(@/assets/img/payment-delivery_bg_1599.jpg);
    background-image: -o-linear-gradient(
        bottom,
        rgba(43, 42, 98, 0.85),
        rgba(43, 42, 98, 0.85)
      ),
      url(@/assets/img/payment-delivery_bg_1599.jpg);
    background-image: linear-gradient(
        0deg,
        rgba(43, 42, 98, 0.85),
        rgba(43, 42, 98, 0.85)
      ),
      url(@/assets/img/payment-delivery_bg_1599.jpg);
  }

  .payment-delivery_play {
    width: 70px;
    height: 70px;
  }

  .payment-delivery_content {
    max-width: 430px;
  }

  .payment-delivery_content {
    padding-left: 0;
  }

  .payment-delivery_title {
    margin-bottom: 50px;
    font-size: 40px;
  }
}

@media screen and (max-width: 1199px) {
  .payment-delivery {
    padding: 61px 0;
    background-image: -webkit-gradient(
        linear,
        left bottom,
        left top,
        from(rgba(43, 42, 98, 0.85)),
        to(rgba(43, 42, 98, 0.85))
      ),
      url(@/assets/img/payment-delivery_bg_1599.jpg);
    background-image: -o-linear-gradient(
        bottom,
        rgba(43, 42, 98, 0.85),
        rgba(43, 42, 98, 0.85)
      ),
      url(@/assets/img/payment-delivery_bg_1599.jpg);
    background-image: linear-gradient(
        0deg,
        rgba(43, 42, 98, 0.85),
        rgba(43, 42, 98, 0.85)
      ),
      url(@/assets/img/payment-delivery_bg_1599.jpg);
  }

  .payment-delivery_play {
    width: 65px;
    height: 65px;
    box-shadow: 0 0 0 15px rgba(255, 255, 255, 0.3);
  }

  .payment-delivery_content {
    max-width: 390px;
  }

  .payment-delivery_title {
    margin-bottom: 45px;
    font-size: 32px;
  }
}

@media screen and (max-width: 991px) {
  .payment-delivery {
    padding: 90px 0 84px 0;
    background-image: -webkit-gradient(
        linear,
        left bottom,
        left top,
        from(rgba(43, 42, 98, 0.85)),
        to(rgba(43, 42, 98, 0.85))
      ),
      url(@/assets/img/payment-delivery_bg_991.jpg);
    background-image: -o-linear-gradient(
        bottom,
        rgba(43, 42, 98, 0.85),
        rgba(43, 42, 98, 0.85)
      ),
      url(@/assets/img/payment-delivery_bg_991.jpg);
    background-image: linear-gradient(
        0deg,
        rgba(43, 42, 98, 0.85),
        rgba(43, 42, 98, 0.85)
      ),
      url(@/assets/img/payment-delivery_bg_991.jpg);
  }

  .payment-delivery_content {
    max-width: 95%;
  }

  .payment-delivery_play {
    left: 30px;
    width: 60px;
    height: 60px;
  }

  .payment-delivery_play::before {
    transform: scale(0.8);
  }

  .payment-delivery_title {
    font-size: 28px;
  }
}

@media screen and (max-width: 767px) {
  .payment-delivery {
    padding: 80px 0;
    background-image: -webkit-gradient(
        linear,
        left bottom,
        left top,
        from(rgba(43, 42, 98, 0.85)),
        to(rgba(43, 42, 98, 0.85))
      ),
      url(@/assets/img/offer-slide_767.jpg);
    background-image: -o-linear-gradient(
        bottom,
        rgba(43, 42, 98, 0.85),
        rgba(43, 42, 98, 0.85)
      ),
      url(@/assets/img/offer-slide_767.jpg);
    background-image: linear-gradient(
        0deg,
        rgba(43, 42, 98, 0.85),
        rgba(43, 42, 98, 0.85)
      ),
      url(@/assets/img/offer-slide_767.jpg);
  }

  .payment-delivery_wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }

  .payment-delivery_img-wrapper {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
  }

  .payment-delivery_content {
    margin-bottom: 34px;
  }

  .payment-delivery_title {
    margin-bottom: 35px;
    font-size: 26px;
  }
}
</style>