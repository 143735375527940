<template>
  <div class="header_logo">
    <picture class="logo_white">
      <source
        srcset="@/assets/img/header-logo_1199.png"
        media="(max-width: 1199px)"
      />
      <source
        srcset="@/assets/img/header-logo_1599.png"
        media="(max-width: 1599px)"
      />
      <img
        class="header_logo-img"
        src="@/assets/img/header-logo.png"
        alt="Логотип компании Alukont"
      />
    </picture>
    <picture class="logo_black">
      <source
        srcset="@/assets/img/header-logo-mod_1199.png"
        media="(max-width: 1199px)"
      />
      <source
        srcset="@/assets/img/header-logo-mod_1599.png"
        media="(max-width: 1599px)"
      />
      <img
        class="header_logo-img"
        src="@/assets/img/header-logo-mod.png"
        alt="Логотип компании Alukont"
      />
    </picture>
  </div>
</template>

<script>
export default {};
</script>

<style>
.header_logo {
  max-width: 240px;
  width: 100%;
}

.logo_black {
  display: none;
}

.main-header-mod-2 .logo_white {
  display: none;
}

.main-header-mod-2 .logo_black {
  display: block;
}

@media screen and (max-width: 1599px) {
  .header_logo {
    max-width: 188px;
  }
}

@media screen and (max-width: 1199px) {
  .header_logo {
    max-width: 166px;
  }
}

@media screen and (max-width: 991px) {
  .header_logo {
    max-width: 109px;
  }
}
</style>