<template>
  <footer class="footer">
    <div class="wrapper wrapper_footer">
      <p class="footer_copyright" v-if="content.copyright">
        {{ content.copyright }}
      </p>
      <p class="footer_development">
        Сайт разработан в
        <a class="footer_development-link" href="#"
          >https://stebnev-studio.ru/</a
        >
      </p>
    </div>
  </footer>
</template>
<script>
export default {
  data() {
    return {
      content: this.$store.state.data.contentResponse.footer,
    };
  },
};
</script>
<style scoped>
.footer {
  padding: 20px 0;
}

.wrapper_footer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.footer_copyright {
  margin: 0;
  font-size: 12px;
  list-style: 1.5;
  color: #323232;
}

.footer_development {
  margin: 0;
  margin-left: auto;
  font-size: 12px;
  list-style: 1.5;
  color: #323232;
}

.footer_development-link {
  position: relative;
  text-decoration: none;
  color: #323232;
}

.footer_development-link:hover {
  color: #7a7a7a;
}

.footer_development-link::before {
  content: "";
  width: 100%;
  height: 1px;
  position: absolute;
  bottom: -3px;
  background-color: #323232;
}

.footer_development-link:hover::before {
  background-color: #7a7a7a;
}

@media screen and (max-width: 1599px) {
  .footer {
    padding: 15px 0;
  }

  .footer_copyright {
    font-size: 10px;
  }

  .footer_development {
    font-size: 10px;
  }
}

@media screen and (max-width: 991px) {
  .wrapper_footer {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .footer_copyright {
    margin-bottom: 5px;
  }

  .footer_development {
    margin: 0;
  }
}
</style>