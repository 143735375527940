<template>
  <header v-bind:class="{ header: true, open: openMenu }">
    <div class="main-header" v-scroll>
      <div class="wrapper header_wrapper">
        <Logo />
        <div class="header_bar">
          <Menu />
          <div class="header-feedback">
            <a
              class="header_bar-phone"
              v-bind:href="`tel:${content.phone_link}`"
              v-if="content.phone_link || content.phone"
              >{{ content.phone }}</a
            >
            <button
              class="header_bar-order"
              type="button"
              v-on:click="orderPhone"
            >
              Заказать звонок
            </button>
            <a class="header_bar-mobile-phone" v-bind:href="`tel:${content.phone_link}`">
              <svg
                width="23"
                height="23"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M16.53 23a6.408 6.408 0 0 1-2.198-.393 23.443 23.443 0 0 1-8.513-5.426A23.441 23.441 0 0 1 .393 8.668a6.362 6.362 0 0 1-.325-3.127A6.512 6.512 0 0 1 3.421.766 6.404 6.404 0 0 1 6.488 0c.338 0 .631.237.702.568l1.128 5.265a.719.719 0 0 1-.194.659L6.196 8.419a18.919 18.919 0 0 0 8.385 8.385l1.927-1.928a.72.72 0 0 1 .66-.194l5.264 1.128a.719.719 0 0 1 .568.702c0 1.09-.258 2.122-.766 3.067a6.513 6.513 0 0 1-4.775 3.353c-.31.045-.62.068-.929.068ZM5.913 1.469a4.978 4.978 0 0 0-3.56 2.12 4.952 4.952 0 0 0-.61 4.589 21.8 21.8 0 0 0 13.08 13.078 4.952 4.952 0 0 0 4.587-.61 4.978 4.978 0 0 0 2.121-3.56l-4.282-.917-2.014 2.013a.719.719 0 0 1-.813.143 20.351 20.351 0 0 1-9.746-9.746.719.719 0 0 1 .142-.814L6.83 5.752l-.918-4.283Z"
                />
              </svg>
            </a>
            <button class="header_bar-burger-menu" v-on:click="mobileMenu">
              <span></span>
              <span></span>
              <span></span>
              <span class="visually-hidden">Открыть меню</span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="header_mobileMenu">
      <div class="header_mobileMenu_background"></div>
      <div class="header_mobileMenu_wrapper">
        <div class="header_mobileMenu_close" v-on:click="mobileMenu">
          <span class="visually-hidden">Открыть меню</span>
        </div>
        <Menu />
        <div class="header_mobileMenu_contacts">
          <a
            class="header_mobileMenu-phone"
            v-bind:href="`tel:${content.phone_link}`"
            v-if="content.phone_link || content.phone"
            >{{ content.phone }}</a
          >
          <button
            class="header_mobileMenu-order"
            type="button"
            v-on:click="orderPhone"
          >
            Заказать звонок
          </button>
          <a class="header_bar-contacts-address" 
            v-bind:href="`${content.address_link}`"
            v-if="content.address_link || content.address"
            target="_blank">{{
              content.address
            }}</a>
          <a
            class="header_bar-contacts-email"
            v-bind:href="`mailto:${content.email}`"
            v-if="content.email"
            >{{ content.email }}</a
          >
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import Logo from "@/components/header/LogoApp.vue";
import Menu from "@/components/header/MenuApp.vue";

export default {
  components: {
    Logo,
    Menu,
  },
  data: function () {
    return {
      content: this.$store.state.data.contentResponse.contacts,
      popupTitle: this.$store.state.data.contentResponse.popup,
    };
  },
  computed: {
    openMenu() {
      return this.$store.state.mobileMenu;
    },
  },
  methods: {
    mobileMenu() {
      this.$store.commit("conditionMenu");
    },
    orderPhone() {
      this.$store.commit("conditionPopup", {
        status: true,
        title: this.popupTitle.title_phone,
        id: 298,
        goal:'zakazat-zvonok-portal'
      });
    },
  },
};
</script>

<style scoped>
.header {
  width: 100%;
  margin: 0 auto;
}

.main-header {
  width: 100%;
  padding: 12px 0;
  position: fixed;
  z-index: 9999;
  background-color: transition;
  transform: translateY(0px);
  transition: transform 0.4s linear, background-color 1s linear;
}

.main-header-mod-1 {
  transform: translateY(-100px);
}

.main-header-mod-2 {
  background-color: #ffffff;
  border-bottom: 1px solid #dfdfdf;
}

.header_wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.header_bar {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  display: flex;
  align-items: center;
}

.header_bar-phone {
  margin-right: 30px;
  font-family: "Lato", sans-serif;
  font-weight: 700;
  font-size: 20px;
  line-height: 1.7;
  text-decoration: none;
  color: #323232;
}

.header_bar-phone:hover {
  color: #686868;
}

.header_bar-order {
  padding: 0;
  border: none;
  position: relative;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
  background-color: transparent;
  font-family: "Lato", sans-serif;
  font-size: 14px;
  line-height: 1.7;
  color: #323232;
}

.header_bar-order:hover {
  color: #686868;
}

.header_bar-order::after {
  content: "";
  width: 100%;
  height: 1px;
  position: absolute;
  left: 0;
  bottom: 0;
  background-color: #2b2a62;
}

.header_bar-mobile-phone {
  display: none;
}

.header_bar-burger-menu {
  display: none;
}

.header_mobileMenu {
  display: none;
}

@media screen and (max-width: 1599px) {
  .main-header {
    padding: 15px 0;
  }

  .header_bar-phone {
    font-size: 18px;
    margin-right: 20px;
  }

  .header_bar-order {
    font-size: 12px;
  }
}

@media screen and (max-width: 991px) {
  .header_wrapper {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  .header_bar {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-flex: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
  }

  .header_bar .header_bar-navigation {
    display: none;
  }

  .header-feedback {
    display: flex;
    align-items: center;
  }

  .header_bar-phone {
    display: none;
  }

  .header_bar-order {
    display: none;
  }

  .header_bar-mobile-phone {
    display: block;
    margin-top: 5px;
    margin-right: 50px;
    -webkit-animation: 1s ease 0s normal none 1 running mobile-phone;
    animation: 1s ease 0s normal none 1 running mobile-phone;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
  }

  @keyframes mobile-phone {
    0% {
      -webkit-transform: rotateZ(0deg) scale(1);
      transform: rotateZ(0deg) scale(1);
    }
    10% {
      -webkit-transform: rotateZ(-30deg);
      transform: rotateZ(-30deg);
    }
    20% {
      -webkit-transform: rotateZ(15deg);
      transform: rotateZ(15deg);
    }
    30% {
      -webkit-transform: rotateZ(-10deg);
      transform: rotateZ(-10deg);
    }
    40% {
      -webkit-transform: rotateZ(7.5deg);
      transform: rotateZ(7.5deg);
    }
    50% {
      -webkit-transform: rotateZ(-6deg) scale(1.1);
      transform: rotateZ(-6deg) scale(1.1);
    }
    60% {
      -webkit-transform: rotateZ(5deg);
      transform: rotateZ(5deg);
    }
    70% {
      -webkit-transform: rotateZ(-4.28571deg);
      transform: rotateZ(-4.28571deg);
    }
    80% {
      -webkit-transform: rotateZ(3.75deg);
      transform: rotateZ(3.75deg);
    }
    90% {
      -webkit-transform: rotateZ(-3.33333deg);
      transform: rotateZ(-3.33333deg);
    }
    100% {
      -webkit-transform: rotateZ(0deg) scale(1);
      transform: rotateZ(0deg) scale(1);
    }
  }

  .header_bar-mobile-phone svg {
    display: block;
  }

  .header_bar-mobile-phone path {
    fill: #ffffff;
  }

  .main-header-mod-2 path {
    fill: #4b4b4b;
  }

  .header_bar-burger-menu {
    display: block;
    width: 24px;
    height: 15px;
    padding: 0;
    border: none;
    background-color: transparent;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    cursor: pointer;
  }

  .header_bar-burger-menu span {
    display: block;
    width: 24px;
    height: 2px;
    margin-bottom: 5px;
    border-radius: 5px;
    background-color: #ffffff;
  }

  .header_bar-burger-menu span:nth-child(2) {
    width: 14px;
    margin-left: auto;
  }

  .header_bar-burger-menu span:nth-child(3) {
    margin-bottom: 0px;
  }

  .main-header-mod-2 .header_bar-burger-menu span {
    background-color: #4b4b4b;
  }

  .header_mobileMenu {
    display: block;
  }

  .header_mobileMenu_background {
    width: 0%;
    height: 100%;
    position: fixed;
    z-index: 999999;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0;
    transition-property: opacity;
    transition-duration: 0.5s;
  }

  .open .header_mobileMenu_background {
    width: 100%;
    opacity: 1;
    z-index: 9999999;
    background-color: rgba(0, 0, 0, 0.7);
  }

  .header_mobileMenu_wrapper {
    position: fixed;
    right: 0;
    z-index: 999999999;
    width: 305px;
    height: 100vh;
    display: flex;
    flex-direction: column;
    max-height: 100%;
    padding: 70px 40px 40px 15px;
    margin: 0;
    transform: translateX(320px);
    background-color: #2b2a62;
    transition: transform 1s ease;
    overflow-y: auto;
  }

  .open .header_mobileMenu_wrapper {
    transform: translateX(0);
  }

  .header_mobileMenu_close {
    width: 24px;
    height: 24px;
    position: absolute;
    top: 30px;
    right: 40px;
    cursor: pointer;
  }

  .header_mobileMenu_close::before,
  .header_mobileMenu_close::after {
    content: "";
    display: block;
    width: 24px;
    height: 2px;
    position: absolute;
    top: 11px;
    background-color: #ffffff;
  }

  .header_mobileMenu_close::before {
    transform: rotate(45deg);
  }

  .header_mobileMenu_close::after {
    transform: rotate(-45deg);
  }

  .header_mobileMenu_contacts {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  .header_mobileMenu-phone {
    text-decoration: none;
    margin-bottom: 5px;
    font-size: 14px;
    color: #ffffff;
  }

  .header_mobileMenu-order {
    padding: 0;
    border: none;
    margin-bottom: 7px;
    position: relative;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    cursor: pointer;
    background-color: transparent;
    font-family: "Lato", sans-serif;
    font-size: 14px;
    line-height: 1.7;
    color: #ffffff;
  }

  .header_mobileMenu-order::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 1px;
    left: 0;
    bottom: 0;
    background-color: #ffffff;
  }

  .header_bar-contacts-address {
    display: block;
    margin-bottom: 7px;
    font-size: 12px;
    line-height: 150%;
    color: #ffffff;
    text-decoration: none;
  }

  .header_bar-contacts-email {
    display: block;
    font-size: 12px;
    line-height: 150%;
    color: #ffffff;
  }
}

@media screen and (max-width: 767px) {
  .header_bar-mobile-phone {
    margin-right: 30px;
  }
}
</style>