<template>
  <Header />
  <main>
    <h1 class="visually-hidden">Раздвижные порталы из алюминия</h1>
    <Offer />
    <Advantages />
    <Catalog />
    <PaymentDelivery />
    <Services />
    <About />
    <Feedback />
    <Contacts />
  </main>
  <Footer />
</template>

<script>
import Header from "@/components/HeaderApp.vue";
import Offer from "@/components/OfferApp.vue";
import Advantages from "@/components/AdvantagesApp.vue";
import Catalog from "@/components/CatalogApp.vue";
import PaymentDelivery from "@/components/PaymentDeliveryApp.vue";
import Services from "@/components/ServicesApp.vue";
import About from "@/components/AboutApp.vue";
import Feedback from "@/components/FeedbackApp.vue";
import Contacts from "@/components/ContactsApp.vue";
import Footer from "@/components/FooterApp.vue";

export default {
  components: {
    Header,
    Offer,
    Advantages,
    Catalog,
    PaymentDelivery,
    Services,
    About,
    Feedback,
    Contacts,
    Footer,
  },
  mounted() {
    let script = document.createElement("script");
    script.src = "//cdn.callibri.ru/callibri.js"; //или полный URL файла js
    document.getElementsByTagName("head")[0].appendChild(script);
  },
};
</script>

<style></style>
