<template>
  <div class="popup">
    <div class="popup_wrapper">
      <div class="popup_info">
        <div class="info-img">
          <svg
            width="53"
            height="53"
            viewBox="0 0 53 53"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              cx="26.5"
              cy="26.5"
              r="25"
              stroke="#5953FA"
              stroke-width="3"
            />
            <path
              d="M37 19L23.681 34L17 26.9412"
              stroke="#5953FA"
              stroke-width="3"
              stroke-linecap="round"
            />
          </svg>
        </div>
        <div class="info-message">
          <h3 class="info-message-title">Ваша заявка принята</h3>
          <p class="info-message-text">
            {{ $store.state.popupInfo.message }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.popup {
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999999999999;
  background-color: rgb(0, 0, 0, 0.85);
}

.popup_wrapper {
  width: 510px;
  padding: 80px 60px;
  border-radius: 10px;
  position: relative;
  background-color: #ffffff;
}

.info-img {
  text-align: center;
  margin-bottom: 30px;

}

.info-img svg {
  width: 53px;
  height: 53px;
  margin: 0 auto;
  display: block;
}

.info-message-title {
  margin: 0;
  margin-bottom: 15px;
  font-size: 18px;
  line-height: 150%;
  color: #101010;
  text-align: center;
}

.info-message-text {
  margin: 0;
  text-align: center;
}

@media screen and (max-width: 1599px) {
  .popup_wrapper {
    width: 450px;
    padding: 60px 50px;
  }
}

@media screen and (max-width: 1199px) {
  .popup_wrapper {
    width: 430px;
    padding: 50px 40px;
  }
}

@media screen and (max-width: 991px) {
  .info-img {
    text-align: center;
    margin-bottom: 20px;
  }

  .info-message-title {
    margin-bottom: 10px;
    font-size: 16px;
  }

  .info-message-text {
    font-size: 12px;
  }
}

@media screen and (max-width: 767px) {
  .popup_wrapper {
    width: 290px;
    padding: 30px 20px;
  }
}
</style>