export default {
  created: function (el) {
    let prevScroll = 0;

    window.addEventListener('scroll', () => {
     let currentScroll = document.documentElement.scrollTop;

      if (currentScroll > prevScroll && currentScroll > 100) { // Скролинг вниз
        el.classList.add("main-header-mod-2");
        el.classList.add("main-header-mod-1");

      } else { // Скролинг вниз
        el.classList.remove("main-header-mod-1");
       

        if (currentScroll < 50) {
          el.classList.remove("main-header-mod-1");
          setTimeout(() => el.classList.remove("main-header-mod-2"), 300)
        }
      }

      prevScroll = currentScroll;
    })
  }
}