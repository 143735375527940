<template>
  <section class="services">
    <div class="wrapper">
      <h2
        class="services_title"
        v-if="content.title"
        data-aos="fade-up"
        data-aos-delay="200"
      >
        {{ content.title }}
      </h2>
      <p
        class="services_desc"
        v-if="content.desc"
        data-aos="fade-up"
        data-aos-delay="200"
      >
        {{ content.desc }}
      </p>
      <ul class="services_list" v-if="content.list">
        <li
          class="services_item"
          v-for="(item, index) in content.list"
          v-bind:key="index"
          data-aos="fade-up"
        data-aos-delay="200"
        >
          <div class="services_item-content">
            <h3 class="services_item-title" v-if="item.name">
              {{ item.name }}
            </h3>
            <p class="services_item-desc" v-if="item.description">
              {{ item.description }}
            </p>
          </div>
          <div class="services_gallery" v-if="item.id_img">
            <ul class="services_gallery-list">
              <li
                class="services_gallery-item"
                v-for="(itemImg, indexImg) in item.id_img"
                v-bind:key="indexImg"
              >
                <picture>
                  <source
                    v-bind:srcset="getImagesUrl(itemImg, 'service767')"
                    media="(max-width: 767px)"
                  />
                  <source
                    v-bind:srcset="getImagesUrl(itemImg, 'service991')"
                    media="(max-width: 991px)"
                  />
                  <source
                    v-bind:srcset="getImagesUrl(itemImg, 'service1199')"
                    media="(max-width: 1199px)"
                  />
                  <source
                    v-bind:srcset="getImagesUrl(itemImg, 'service1599')"
                    media="(max-width: 1599px)"
                  />
                  <img
                    class="services_item-img"
                    v-bind:src="getImagesUrl(itemImg, 'service1920')"
                    alt=""
                  />
                </picture>
              </li>
            </ul>
          </div>
        </li>
      </ul>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      content: this.$store.state.data.contentResponse.services,
      media: this.$store.state.data.mediaResponse,
    };
  },
  methods: {
    getImagesUrl(id, size) {
      return (
        this.media[id][size] ? this.media[id][size].source_url : this.media[id].full.source_url
      );
    },
  },
};
</script>
<style scoped>
.services {
  padding: 150px 0;
}

.services_title {
  margin: 0;
  margin-bottom: 10px;
  font-size: 48px;
  font-weight: 700;
  line-height: 1.5;
  text-align: center;
  color: #323232;
}

.services_desc {
  max-width: 690px;
  margin: 0 auto;
  margin-bottom: 60px;
  text-align: center;
}

.services_list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.services_item {
  margin-bottom: 70px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.services_item:last-child {
  margin-bottom: 0;
}

.services_item-content {
  -ms-flex-preferred-size: 450px;
  flex-basis: 450px;
}

.services_item-title {
  margin: 0;
  margin-bottom: 30px;
  font-size: 30px;
  line-height: 1.5;
  color: #323232;
}

.services_item-desc {
  margin: 0;
}

.services_gallery-list {
  padding: 0;
  margin: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  list-style: none;
}

.services_gallery-item {
  margin-right: 30px;
}

.services_gallery-item:last-child {
  margin-right: 0;
}

.services_item-img {
  width: 330px;
  height: 270px;
  display: block;
}

@media screen and (max-width: 1599px) {
  .services {
    padding: 120px 0;
  }

  .services_title {
    font-size: 40px;
  }

  .services_desc {
    margin-bottom: 50px;
  }

  .services_item {
    margin-bottom: 60px;
  }

  .services_item-content {
    -ms-flex-preferred-size: 340px;
    flex-basis: 340px;
  }

  .services_item-title {
    margin-bottom: 25px;
    font-size: 24px;
  }

  .services_gallery-item {
    margin-right: 30px;
  }

  .services_item-img {
    width: 246px;
    height: 202px;
  }
}

@media screen and (max-width: 1199px) {
  .services {
    padding: 100px 0;
  }

  .services_title {
    font-size: 32px;
    margin-bottom: 15px;
  }

  .services_desc {
    margin-bottom: 45px;
  }

  .services_item {
    margin-bottom: 50px;
  }

  .services_item-content {
    -ms-flex-preferred-size: 305px;
    flex-basis: 305px;
  }

  .services_item-title {
    margin-bottom: 20px;
    font-size: 20px;
  }

  .services_gallery-item {
    margin-right: 30px;
  }

  .services_item-img {
    width: 219px;
    height: 180px;
  }
}

@media screen and (max-width: 991px) {
  .services {
    padding: 90px 0;
  }

  .services_title {
    font-size: 28px;
    margin-bottom: 5px;
  }

  .services_desc {
    margin-bottom: 45px;
  }

  .services_item {
    margin-bottom: 45px;
  }

  .services_item-content {
    -ms-flex-preferred-size: 290px;
    flex-basis: 290px;
  }

  .services_item-title {
    margin-bottom: 16px;
    font-size: 20px;
  }

  .services_gallery-item {
    margin-right: 15px;
  }

  .services_item-img {
    width: 136px;
    height: 112px;
  }
}

@media screen and (max-width: 767px) {
  .services {
    padding: 80px 0;
  }

  .services_title {
    font-size: 26px;
  }

  .services_desc {
    margin-bottom: 35px;
  }

  .services_item {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .services_item-content {
    margin-bottom: 20px;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
  }

  .services_item-title {
    margin-bottom: 20px;
    font-size: 16px;
  }

  .services_gallery-list {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  .services_gallery-item {
    margin: 0;
  }

  .services_item-img {
    width: 138px;
    height: 114px;
  }
}
</style>