import IMask from 'imask';

export default {
  created: function (el) {
    var maskOptions = {
      mask: '+{7} (000) 000-00-00'
    };

    IMask(el, maskOptions);
  }
}