<template>
  <section id="contacts" class="сontacts">
    <Map />
    <h2 class="visually-hidden">Контактная информация</h2>
    <div class="wrapper">
      <div class="сontacts_info">
        <p class="сontacts_item" v-if="content.address">
          <span class="сontacts_item_title">Адрес</span>
          <a
            class="сontacts_item_value сontacts_item_link"
            v-bind:href="`${content.address_link}`"
            v-if="content.address_link || content.address"
            target="_blank"
            >{{ content.address }}
          </a>
        </p>
        <p class="сontacts_item" v-if="content.phone">
          <span class="сontacts_item_title">Телефон</span>
          <a
            class="сontacts_item_value сontacts_item_link"
            v-bind:href="`tel:${content.phone_link}`"
            v-if="content.phone_link || content.phone"
            >{{ content.phone }}</a
          >
        </p>
        <p class="сontacts_item" v-if="content.email">
          <span class="сontacts_item_title">E-mail</span>
          <a
            class="сontacts_item_value сontacts_item_link"
            v-bind:href="`mailto:${content.email}`"
            v-if="content.email"
            >{{ content.email }}</a
          >
        </p>
        <div
          class="сontacts_item сontacts_item-weekdays"
          v-if="content.weekdays || content.weekends"
        >
          <p class="сontacts_weekdays" v-if="content.weekdays">
            <span class="сontacts_weekdays-title">Пн-Пт</span>
            <span class="сontacts_weekdays-value">{{ content.weekdays }}</span>
          </p>
          <p class="сontacts_weekdays" v-if="content.weekends">
            <span class="сontacts_weekdays-title">Cб-Вс</span>
            <span class="сontacts_weekdays-value">{{ content.weekends }}</span>
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Map from "@/components/сontacts/MapApp.vue";

export default {
  components: {
    Map,
  },
  data() {
    return {
      content: this.$store.state.data.contentResponse.contacts,
    };
  },
};
</script>

<style scoped>
.сontacts {
  height: 615px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  z-index: 0;
  background-color: #e9e9e9;
}

.сontacts_info {
  width: 570px;
  height: 315px;
  margin: auto 0;
  padding: 50px 90px;
  position: absolute;
  top: 0;
  bottom: 0;
  background-color: #ffffff;
  -webkit-box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.15);
}

.сontacts_item {
  margin: 0;
  margin-bottom: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  line-height: 150%;
  color: #323232;
}

.сontacts_item:last-child {
  margin-bottom: 0;
}

.сontacts_item_title {
  -ms-flex-preferred-size: 120px;
  flex-basis: 120px;
  font-weight: 700;
  color: #5953fa;
}

.сontacts_item_link {
  color: #323232;
  text-decoration: none;
}

.сontacts_item-weekdays {
  display: block;
}

.сontacts_weekdays {
  margin: 0;
  margin-bottom: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.сontacts_weekdays:last-child {
  margin: 0;
}

.сontacts_weekdays-title {
  -ms-flex-preferred-size: 120px;
  flex-basis: 120px;
  font-weight: 700;
  color: #5953fa;
}

@media screen and (max-width: 1599px) {
  .сontacts {
    height: 515px;
  }

  .сontacts_info {
    width: 522px;
    height: 265px;
    padding: 40px 60px;
  }

  .сontacts_item {
    margin-bottom: 25px;
    font-size: 14px;
  }
}

@media screen and (max-width: 1199px) {
  .сontacts {
    height: 415px;
  }

  .сontacts_info {
    width: 385px;
    padding: 50px 30px;
  }

  .сontacts_item {
    margin-bottom: 20px;
    font-size: 12px;
  }

  .сontacts_item_title {
    -ms-flex-preferred-size: 90px;
    flex-basis: 90px;
  }

  .сontacts_weekdays-title {
    -ms-flex-preferred-size: 90px;
    flex-basis: 90px;
  }
}

@media screen and (max-width: 991px) {
  .сontacts {
    height: 395px;
  }

  .сontacts_info {
    width: 290px;
    height: 215px;
    padding: 30px 15px;
  }

  .сontacts_item {
    font-size: 12px;
  }

  .сontacts_item_title {
    -ms-flex-preferred-size: 70px;
    flex-basis: 70px;
  }

  .сontacts_weekdays-title {
    -ms-flex-preferred-size: 70px;
    flex-basis: 70px;
  }
}

@media screen and (max-width: 767px) {
  .сontacts {
    height: 545px;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .сontacts_info {
    width: 290px;
    padding: 30px 15px;
    margin-top: 50px;
  }

  .сontacts_item {
    font-size: 12px;
  }

  .сontacts_item_title {
    -ms-flex-preferred-size: 70px;
    flex-basis: 70px;
  }

  .сontacts_weekdays-title {
    -ms-flex-preferred-size: 70px;
    flex-basis: 70px;
  }
}
</style>