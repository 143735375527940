<template>
  <section class="offer">
    <h2 class="visually-hidden">Торговое предложение</h2>
    <div class="swiper offer-swiper">
      <ul class="offer_list swiper-wrapper" v-if="content.list">
        <li
          class="offer_item swiper-slide"
          v-for="(item, index) in content.list"
          v-bind:key="index"
        >
          <div class="wrapper offer-wrapper">
            <picture v-if="item.id_img">
              <source
                v-bind:srcset="getImagesUrl(item.id_img, 'offer767')"
                media="(max-width: 767px)"
              />
              <source
                v-bind:srcset="getImagesUrl(item.id_img, 'offer991')"
                media="(max-width: 991px)"
              />
              <source
                v-bind:srcset="getImagesUrl(item.id_img, 'offer1199')"
                media="(max-width: 1199px)"
              />
              <source
                v-bind:srcset="getImagesUrl(item.id_img, 'offer1599')"
                media="(max-width: 1599px)"
              />
              <img
                class="offer_img"
                v-bind:src="getImagesUrl(item.id_img, 'offer1920')"
                alt=""
              />
            </picture>
            <div class="offer_content">
              <div
                class="offer_content-title"
                v-if="item.title"
              >
                {{ item.title }}
              </div>
              <p
                class="offer_content-desc"
                v-if="item.desc"
              >
                {{ item.desc }}
              </p>
              <div class="offer_content-bnt-wrap">
                <button class="bnt offer_content-bnt" v-scroll-to="'#catalog'" v-if="item.button">
                  <span class="bnt-text bnt-text--one">{{ item.button }}</span>
                  <span class="bnt-text bnt-text--two">{{ item.button }}</span>
                </button>
              </div>
            </div>
          </div>
        </li>
      </ul>
      <div class="offer_slider-bar">
        <div class="offer_slider-bar_controls">
          <span class="offer_slider-bar_prev swiper-button-prev">
            <svg width="16" height="17" viewBox="0 0 16 17" fill="none">
              <path d="M8 1L1 8.5L8 16" stroke="white" stroke-linecap="round" />
              <rect x="1" y="8" width="15" height="1" rx="0.5" fill="#FFF9F9" />
            </svg>
            <span class="visually-hidden">Предыдущий слайд</span>
          </span>
          <span class="offer_slider-bar_next swiper-button-next">
            <svg width="16" height="17" viewBox="0 0 16 17" fill="none">
              <path
                d="M8 16L15 8.5L8 1"
                stroke="white"
                stroke-linecap="round"
              />
              <rect y="8" width="15" height="1" rx="0.5" fill="#FFF9F9" />
            </svg>
            <span class="visually-hidden">Следующий слайд</span>
          </span>
        </div>
        <div class="offer_slider-bar_numbering">
          <div class="offer_slider-bar_desktop swiper-pagination"></div>
          <div class="offer_slider-bar_mobile swiper-pagination-mobile"></div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Swiper, { Navigation, Pagination } from 'swiper';
import 'swiper/css';

import 'swiper/css/pagination';

export default {
  data() {
    return {
      content: this.$store.state.data.contentResponse.offer,
      media: this.$store.state.data.mediaResponse,
    };
  },
  methods: {
    getImagesUrl(id, size) {
      return (
        this.media[id][size] ? this.media[id][size].source_url : this.media[id].full.source_url
      );
    },
  },
  mounted() {
      new Swiper('.swiper', {
        modules: [Navigation, Pagination],
        loop: true,
        speed: 1000,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      scrollbar: {
        el: '.swiper-scrollbar',
      },
      breakpoints: {
        320: {
          pagination: {
            el: '.swiper-pagination-mobile',
            type: 'bullets',
          }
        },
        991: {
          pagination: {
            el: '.swiper-pagination',
            type: 'fraction',
            formatFractionCurrent: num => (num > 9) ? num : '0' + num,
            formatFractionTotal: num => (num > 9) ? num : '0' + num,
          }
        }
      }
    });
  },
};
</script>

<style>
.offer {
  width: 100%;
  height: 100vh;
  min-height: 650px;
  position: relative;
  z-index: 0;
  background-color: #eeeeee;
}

.offer-swiper {
  width: 100%;
  height: 100%;
}

.offer-wrapper {
  height: 100%;
}

.offer_list {
  padding: 0;
  margin: 0;
  list-style: none;
}

.offer_item::before {
  content: "";
  width: calc(50vw + 106px);
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  background-color: rgba(43, 42, 98, 0.9);
}

.offer_img {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -2;
  left: 0;
  top: 0;
}

.offer_content {
  height: 100%;
  padding-bottom: 55px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.offer_content-title {
  width: 680px;
  margin: 0;
  margin-bottom: 40px;
  font-weight: 700;
  font-size: 60px;
  line-height: 1.2;
  color: #ffffff;
  transform: translateY(-50px);
  opacity: 0;
  transition: .7s ease 0.5s;
}

.swiper-slide-active .offer_content-title {
  transform: translateY(0px);
  opacity: 1;
}

.offer_content-desc {
  width: 355px;
  max-height: 410px;
  margin: 0;
  margin-bottom: 40px;
  color: #ffffff;
  overflow: hidden;
  transform: translateY(-50px);
  opacity: 0;
  transition: .8s ease 0.5s;
}

.swiper-slide-active .offer_content-desc {
  transform: translateY(0px);
  opacity: 1;
}

.offer_content-bnt-wrap {
  transform: translateY(-50px);
  opacity: 0;
  transition: .9s ease 0.5s;
}

.swiper-slide-active .offer_content-bnt-wrap {
  transform: translateY(0px);
  opacity: 1;
}

.offer_content-bnt:hover {
  background-color: #ffffff !important;
  color: #2b2a62;
}

.offer_slider-bar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: absolute;
  bottom: 60px;
  left: calc(50% - 700px);
  z-index: 1;
}

.offer_slider-bar_controls {
  margin-right: 45px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.offer_slider-bar_prev,
.offer_slider-bar_next {
  position: relative;
  width: 50px;
  height: 50px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
  overflow: hidden;
  -webkit-transition: background-color 0.2s ease;
  -o-transition: background-color 0.2s ease;
  transition: background-color 0.2s ease;
}

.offer_slider-bar_prev {
  margin-right: 15px;
}

.offer_slider-bar_prev:hover,
.offer_slider-bar_next:hover {
  background-color: rgba(255, 255, 255, 0.14);
}

.offer_slider-bar_prev::before,
.offer_slider-bar_next::before {
  content: "";
  width: 50px;
  height: 50px;
  position: absolute;
  background-color: #ffffff;
  -webkit-transition: -webkit-transform 0.4s ease;
  transition: -webkit-transform 0.4s ease;
  -o-transition: transform 0.4s ease;
  transition: transform 0.4s ease;
  transition: transform 0.4s ease, -webkit-transform 0.4s ease;
}

.offer_slider-bar_prev::before {
  -webkit-transform: translateX(50px);
  -ms-transform: translateX(50px);
  transform: translateX(50px);
}

.offer_slider-bar_next::before {
  -webkit-transform: translateX(-50px);
  -ms-transform: translateX(-50px);
  transform: translateX(-50px);
}

.offer_slider-bar_prev:hover::before,
.offer_slider-bar_next:hover::before {
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
}

.offer_slider-bar_prev:hover svg,
.offer_slider-bar_next:hover svg {
  position: relative;
  z-index: 1;
}

.offer_slider-bar_prev:hover svg rect,
.offer_slider-bar_next:hover svg rect {
  fill: #2b2a62;
}

.offer_slider-bar_prev:hover svg path,
.offer_slider-bar_next:hover svg path {
  stroke: #2b2a62;
}

.offer_slider-bar_desktop {
  position: static;
  line-height: 1.5;
  color: #8686a3;
}

.swiper-pagination-current {
  color: #ffffff;
}

.offer_slider-bar_mobile {
  display: none;
}

@media screen and (max-width: 1599px) {
  .offer {
    min-height: 550px;
  }

  .offer_item::before {
    width: calc(50vw + 65px);
  }

  .offer_content {
    padding-bottom: 50px;
  }

  .offer_content-title {
    width: 500px;
    margin-bottom: 30px;
    font-size: 44px;
  }

  .offer_content-desc {
    width: 310px;
    font-size: 14px;
    margin-bottom: 30px;
  }

  .offer_slider-bar {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: absolute;
    bottom: 50px;
    left: calc(50% - 530px);
  }

  .offer_slider-bar_controls {
    margin-right: 88px;
  }

  .offer_slider-bar_prev,
  .offer_slider-bar_next {
    width: 45px;
    height: 45px;
  }

  .offer_slider-bar_prev {
    margin-right: 15px;
  }

  .offer_slider-bar_prev::before,
  .offer_slider-bar_next::before {
    width: 45px;
    height: 45px;
  }

  .offer_slider-bar_prev::before {
    -webkit-transform: translateX(45px);
    -ms-transform: translateX(45px);
    transform: translateX(45px);
  }

  .offer_slider-bar_next::before {
    -webkit-transform: translateX(-45px);
    -ms-transform: translateX(-45px);
    transform: translateX(-45px);
  }
}

@media screen and (max-width: 1199px) {
  .offer {
    min-height: 500px;
  }

  .offer_item::before {
    width: calc(50vw + 154px);
  }

  .offer_content {
    padding-bottom: 45px;
  }

  .offer_content-title {
    font-size: 34px;
  }

  .offer_content-desc {
    width: 270px;
    font-size: 12px;
  }

  .offer_slider-bar {
    bottom: 40px;
    left: calc(50% - 485px);
  }

  .offer_slider-bar_controls {
    margin-right: 78px;
  }

  .offer_slider-bar_prev,
  .offer_slider-bar_next {
    width: 40px;
    height: 40px;
  }

  .offer_slider-bar_prev {
    margin-right: 15px;
  }

  .offer_slider-bar_prev::before,
  .offer_slider-bar_next::before {
    width: 40px;
    height: 40px;
  }

  .offer_slider-bar_prev::before {
    -webkit-transform: translateX(40px);
    -ms-transform: translateX(40px);
    transform: translateX(40px);
  }

  .offer_slider-bar_next::before {
    -webkit-transform: translateX(-40px);
    -ms-transform: translateX(-40px);
    transform: translateX(-40px);
  }

  .offer_slider-bar_desktop {
    font-size: 14px;
  }
}

@media screen and (max-width: 991px) {
  .offer {
    min-height: 840px;
  }

  .offer_item::before {
    width: 100%;
    background-color: #2b2a62;
  }

  .offer_img {
    width: 100%;
    max-height: 429px;
    z-index: 1;
    left: 0;
    top: auto;
    bottom: 70px;
  }

  .offer_content {
    height: calc(100% - 429px);
    padding-bottom: 0;
  }

  .offer_content-title {
    width: 380px;
    margin-bottom: 20px;
    font-size: 30px;
    transition-duration: 1s;
  }

  .offer_content-desc {
    margin-bottom: 25px;
  }

  .offer_slider-bar {
    width: 100%;
    height: 70px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    left: 0;
    bottom: 0;
    background-color: #f7f7f7;
  }

  .offer_slider-bar_controls {
    display: none;
  }

  .offer_slider-bar_desktop {
    display: none;
  }

  .offer_slider-bar_mobile {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
  }

  .offer .swiper-pagination-bullet {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #5953fa;
    background-color: transparent;
    opacity: 1;
  }

  .offer .swiper-pagination-bullet-active {
    width: 10px;
    height: 10px;
    background-color: #5953fa;
    opacity: 1;
  }
}

@media screen and (max-width: 767px) {
  .offer {
    min-height: 580px;
  }

  .offer_img {
    max-height: 179px;
    bottom: 50px;
  }

  .offer_content {
    height: calc(100% - 179px);
  }

  .offer_content-title {
    width: 290px;
    font-size: 28px;
  }

  .offer_slider-bar {
    height: 50px;
  }
}
</style>