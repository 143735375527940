<template>
  <Transition name="close-reloader">
    <Preloader v-if="$store.state.preloader" />
  </Transition>
  <IndexPage v-if="!$store.state.preloader" />
  <Transition name="popup">
    <FormApp v-if="$store.state.popupForm.status" />
  </Transition>
  <Transition name="popup">
    <InfoApp v-if="$store.state.popupInfo.status" />
  </Transition>
</template>

<script>
import IndexPage from "@/views/IndexPage.vue";
import Preloader from "@/components/PreloaderApp.vue";
import FormApp from "@/components/popup/FormApp.vue";
import InfoApp from "@/components/popup/InfoApp.vue";
import AOS from "aos";

export default {
  name: "App",
  components: {
    IndexPage,
    Preloader,
    FormApp,
    InfoApp,
  },
  mounted() {
    this.$store.dispatch("loadingData");

    AOS.init({
      once: true,
      duration: 1000,
    });
  },
};
</script>
<style>
.close-reloader-leave-active {
  transition: opacity 0.8s ease;
}

.close-reloader-leave-to {
  opacity: 0;
}

.popup-enter-active,
.popup-leave-active {
  transition: opacity 0.7s ease;
}

.popup-enter-from,
.popup-leave-to {
  opacity: 0;
}

[data-aos="custom-appearance"] {
  transform: translateY(-50px);
  opacity: 0;
}

[data-aos="custom-appearance"].aos-animate {
  transform: translateY(0px);
  opacity: 1;
}

[data-aos="custom-appearance-1"] {
  transform: translateY(50px);
  opacity: 0;
}

[data-aos="custom-appearance-1"].aos-animate {
  transform: translateY(0px);
  opacity: 1;
}
</style>
