<template>
  <nav class="header_bar-navigation">
    <ul class="header_bar-navigation-list" v-if="content">
      <li
        class="header_bar-navigation-item"
        v-for="item in content"
        v-bind:key="item.id"
      >
        <a class="header_bar-navigation-link" href="#" v-scroll-to="item.url">{{
          item.title
        }}</a>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  data() {
    return {
      content: this.$store.state.data.menuResponse,
      content1: "#catalog",
    };
  },
};
</script>

<style>
.header_bar-navigation {
  max-width: 560px;
  margin-right: auto;
}

.header_bar-navigation-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 0;
  margin: 0;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  list-style: none;
}

.header_bar-navigation-item {
  margin-right: 55px;
}

.header_bar-navigation-item:last-child {
  margin-right: 0;
}

.header_bar-navigation-link {
  display: block;
  padding: 9px 0;
  position: relative;
  font-family: "Lato", sans-serif;
  font-weight: 700;
  font-size: 14px;
  line-height: 1.5;
  text-decoration: none;
  color: #ffffff;
}

.main-header-mod-2 .header_bar-navigation-link {
  color: #323237;
}

.noscroll .header_bar-navigation-link {
  color: #ffffff!important;
}

.header-mod-2 .header_bar-navigation-link {
  color: #323232;
}

.header_bar-navigation-link::after {
  content: "";
  width: calc(100% + 4px);
  height: 4px;
  border-radius: 5px;
  opacity: 0;
  position: absolute;
  left: -2px;
  bottom: 0;
  background-color: #5953fa;
  -webkit-transition: opacity 0.3s ease-in;
  -o-transition: opacity 0.3s ease-in;
  transition: opacity 0.3s ease-in;
}

.header_bar-navigation-link:hover::after {
  opacity: 1;
}

@media screen and (max-width: 1599px) {
  .header_bar-navigation {
    max-width: 540px;
  }

  .header_bar-navigation-item {
    margin-right: 40px;
  }

  .header_bar-navigation-link {
    display: block;
    padding: 7px 0;
    font-size: 12px;
    line-height: 1.5;
  }

  .header_bar-navigation-link::after {
    height: 2px;
  }
}

@media screen and (max-width: 991px) {
  .header_bar-navigation {
    margin-left: auto;
    margin-right: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .header_bar-navigation-list {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
  }

  .header_bar-navigation-item {
    margin-right: 0;
    margin-bottom: 15px;
  }

  .header_bar-navigation-link {
    display: inline-block;
    padding-top: 0;
    font-size: 14px;
  }
}
</style>