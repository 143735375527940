<template>
  <div id="map" class="map"></div>
</template>

<script>
import ymaps from "ymaps";

export default {
  mounted() {
    ymaps
      .load(
        "https://api-maps.yandex.ru/2.1/?apikey=aebe51a2-52a1-4bd7-a8e1-9ca0b07b7ecd&lang=ru_RU"
      )
      .then((maps) => {
        const map = new maps.Map("map", {
          center: [51.662909701599, 39.19479883723717],
          controls: ["zoomControl"],
          zoom: 12,
        });

        let myPlacemark = new maps.Placemark(
          // Координаты метки
          [51.63043407232998, 39.23757599999996],
          {},
          {
            iconLayout: "default#image",
            iconImageHref:
              "https://api-portal.alukont.ru/wp-content/uploads/2022/03/map.svg",
            iconImageSize: [50, 66],
            iconImageOffset: [-25, -66],
          }
        );
        // Добавление метки на карту
        map.geoObjects.add(myPlacemark);
      })
      .catch((error) => console.log("Failed to load Yandex Maps", error));
  },
};
</script>

<style>
.map {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
}

[class*="ymaps-2"][class*="-ground-pane"] {
  filter: grayscale(1);
  -ms-filter: grayscale(1);
  -webkit-filter: grayscale(1);
  -moz-filter: grayscale(1);
  -o-filter: grayscale(1);
}

[class*="ymaps-2"][class*="-copyrights-promo"] {
  display: none;
}
</style>