<template>
  <section id="catalog" class="catalog">
    <div class="wrapper">
      <h2
        class="catalog_title"
        v-if="content.title"
        data-aos="custom-appearance-1"
        data-aos-delay="400"
      >
        {{ content.title }}
      </h2>
      <p
        class="catalog_desc"
        v-if="content.desc"
        data-aos="custom-appearance-1"
        data-aos-delay="400"
      >
        {{ content.desc }}
      </p>
      <ul class="catalog_list" v-if="content.list">
        <li
          class="catalog_item"
          v-for="(item, index) in content.list"
          v-bind:key="index"
          data-aos="custom-appearance-1"
        >
          <div class="catalog_item-content">
            <div class="catalog_item-img-wrapper">
              <picture v-if="item.id_img">
                <source
                  v-bind:srcset="getImagesUrl(item.id_img, 'productImg767')"
                  media="(max-width: 767px)"
                />
                <source
                  v-bind:srcset="getImagesUrl(item.id_img, 'productImg991')"
                  media="(max-width: 991px)"
                />
                <source
                  v-bind:srcset="getImagesUrl(item.id_img, 'productImg1199')"
                  media="(max-width: 1199px)"
                />
                <source
                  v-bind:srcset="getImagesUrl(item.id_img, 'productImg1599')"
                  media="(max-width: 1599px)"
                />
                <img
                  class="catalog_item-img"
                  v-bind:src="getImagesUrl(item.id_img, 'productImg1920')"
                  alt=""
                />
              </picture>
              <img
                src="@/assets/img/no-img.jpg"
                alt="Изображение товара отсутствует"
                v-else
              />
            </div>
            <div class="catalog_item-info">
              <h3 class="catalog_item-title" v-if="item.name">
                {{ item.name }}
              </h3>
              <p class="catalog_item-desc" v-if="item.specifications">
                {{ item.specifications }}
              </p>
              <p class="catalog_item-price" v-if="item.price">
                {{ item.price }} руб/м2
              </p>
            </div>
          </div>
          <button class="bnt catalog_item-bnt" v-on:click="catalogPhone">
            <span class="bnt-text bnt-text--one">{{ item.button }}</span>
            <span class="bnt-text bnt-text--two">{{ item.button }}</span>
          </button>
        </li>
      </ul>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      content: this.$store.state.data.contentResponse.catalog,
      media: this.$store.state.data.mediaResponse,
      popupTitle: this.$store.state.data.contentResponse.popup,
    };
  },
  methods: {
    getImagesUrl(id, size) {
      return (
        this.media[id][size] ? this.media[id][size].source_url : this.media[id].full.source_url
      );
    },
    catalogPhone() {
      this.$store.commit("conditionPopup", {
        status: true,
        title: this.popupTitle.title_catalog,
        id: 299,
        goal:'zakazat-raschyot-portal'
      });
    },
  },
};
</script>

<style scoped>
.catalog {
  padding: 150px 0;
}

.catalog_title {
  margin: 0;
  margin-bottom: 15px;
  font-size: 48px;
  font-weight: 700;
  line-height: 1.5;
  text-align: center;
  color: #323232;
}

.catalog_desc {
  max-width: 690px;
  margin: 0 auto;
  margin-bottom: 60px;
  text-align: center;
}

.catalog_list {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 30px 1fr 30px 1fr 30px 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  -ms-grid-rows: 1fr;
  grid-template-rows: 1fr;
  gap: 60px 30px;
  padding: 0;
  margin: 0;
  z-index: 0;
  list-style: none;
}

.catalog_item {
  margin-bottom: -80px;
  z-index: 0;
  position: relative;
  padding: 5px 25px 25px 25px;
  border-radius: 0px 0px 50px 50px;
  background-color: #ffffff;
  transition: box-shadow 0.2s ease-in, z-index 0.1s ease-in,
    opacity 0.5s ease-in, transform 0.4s ease-in !important;
}

.catalog_item:hover {
  z-index: 1;
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.15);
}

.catalog_item-content {
  width: calc(100% + 50px);
  padding: 5px 25px 25px 25px;
  border: 1px solid #eeeeee;
  position: relative;
  top: -5px;
  left: -25px;
}

.catalog_item:hover .catalog_item-content {
  border: none;
}

.catalog_item-img-wrapper {
  width: 100%;
  max-height: 272px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.catalog_item-img {
  display: block;
}

.catalog_item-title {
  margin: 0;
  margin-bottom: 15px;
  font-size: 16px;
  font-weight: 700;
  line-height: 1.5;
  color: #323232;
}

.catalog_item-desc {
  margin: 0;
  margin-bottom: 15px;
  font-size: 14px;
  line-height: 1.5;
}

.catalog_item-price {
  margin: 0;
  font-size: 20px;
  font-weight: 700;
  line-height: 1.5;
  color: #27238b;
}

.catalog_item-bnt {
  width: 100%;
  opacity: 0;
  transition: opacity 0.1s ease;
}

.catalog_item:hover .catalog_item-bnt {
  opacity: 1;
}

@media screen and (max-width: 1599px) {
  .catalog {
    padding: 120px 0;
  }

  .catalog_title {
    margin-bottom: 10px;
    font-size: 40px;
  }

  .catalog_desc {
    margin-bottom: 50px;
    font-size: 14px;
  }

  .catalog_list {
    gap: 50px 30px;
  }

  .catalog_item {
    padding: 5px 20px 20px 20px;
    border-radius: 0px 0px 30px 30px;
    margin-bottom: -60px;
  }

  .catalog_item-content {
    width: calc(100% + 40px);
    padding: 5px 20px 20px 20px;
    left: -20px;
  }

  .catalog_item-img-wrapper {
    max-height: 203px;
  }

  .catalog_item-title {
    margin-bottom: 10px;
    font-size: 14px;
  }

  .catalog_item-desc {
    margin-bottom: 10px;
    font-size: 12px;
  }

  .catalog_item-price {
    font-size: 16px;
  }
}

@media screen and (max-width: 1199px) {
  .catalog {
    padding: 120px 0;
  }

  .catalog_title {
    margin-bottom: 5px;
    font-size: 32px;
  }

  .catalog_desc {
    margin-bottom: 40px;
    font-size: 12px;
  }

  .catalog_list {
    gap: 30px 45px;
  }

  .catalog_item {
    padding: 5px 15px 15px 15px;
    border-radius: 0px 0px 20px 20px;
    margin-bottom: -40px;
  }

  .catalog_item-content {
    width: calc(100% + 30px);
    padding: 5px 15px 15px 15px;
    left: -15px;
  }

  .catalog_item-img-wrapper {
    max-height: 181px;
    padding: 5px 15px;
  }

  .catalog_item-title {
    margin-bottom: 8px;
    font-size: 12px;
  }

  .catalog_item-desc {
    margin-bottom: 8px;
    font-size: 10px;
  }

  .catalog_item-price {
    font-size: 14px;
  }

  .catalog_item-bnt {
    padding-top: 9px;
    padding-bottom: 9px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}

@media screen and (max-width: 991px) {
  .catalog {
    padding: 90px 0;
  }

  .catalog_title {
    font-size: 28px;
  }

  .catalog_desc {
    margin-bottom: 45px;
  }

  .catalog_list {
    -ms-grid-columns: 1fr 20px 1fr 20px 1fr;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px 20px;
  }

  .catalog_item {
    border: 1px solid #eeeeee;
    margin-bottom: 0px;
  }

  .catalog_item:hover {
    box-shadow: none;
  }

  .catalog_item-content {
    width: 100%;
    padding: 0;
    border: none;
    position: static;
  }

  .catalog_item-img-wrapper {
    max-height: 152px;
    padding: 0;
  }

  .catalog_item-info {
    padding: 0;
  }

  .catalog_item-title {
    padding: 0;
  }

  .catalog_item-desc {
    padding: 0;
  }

  .catalog_item-price {
    padding: 0;
    margin-bottom: 10px;
  }

  .catalog_item-bnt {
    opacity: 1;
  }
}

@media screen and (max-width: 767px) {
  .catalog {
    padding: 80px 0;
  }

  .catalog_title {
    font-size: 26px;
  }

  .catalog_desc {
    margin-bottom: 35px;
  }

  .catalog_list {
    -ms-grid-columns: 1fr 14px 1fr;
    grid-template-columns: 1fr 1fr;
    gap: 35px 14px;
  }

  .catalog_item {
    padding: 5px 10px 10px 10px;
  }

  .catalog_item-img-wrapper {
    max-height: 114px;
  }

  .catalog_item-info {
    padding: 2px 0 0 0;
  }

  .catalog_item-title {
    padding: 0;
  }

  .catalog_item-desc {
    padding: 0;
  }

  .catalog_item-price {
    padding: 0;
    margin-bottom: 0;
  }

  .catalog_item-bnt {
    padding: 9px 20px 9px 20px;
    left: 0;
    width: calc(100% + 20px);
    left: -10px;
    bottom: -10px;
  }
}
</style>