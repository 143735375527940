<template>
  <section class="feedback">
    <div class="wrapper">
      <h2 class="feedback_title" v-if="content.title">{{ content.title }}</h2>
      <p class="feedback_desc" v-if="content.desc">{{ content.desc }}</p>
      <form class="feedback_form">
        <p class="feedback_elem">
          <label class="feedback_label" for="name">Имя</label>
          <input
            class="feedback_input"
            type="text"
            name="name"
            id="name"
            v-model="dataForm.your_name"
          />
        </p>
        <p class="feedback_elem">
          <label v-bind:class="{ feedback_label: true, errors: errors.your_phone_status }" for="phone">{{
            errors.your_phone_status ? errors.your_phone : "* Телефон"
          }}</label>
          <input
            class="feedback_input"
            type="tel"
            name="phone"
            id="phone"
            v-maskForm
            v-model="dataForm.your_phone"
          />
        </p>
        <p class="feedback_elem feedback_elem-checkbox">
          <label v-bind:class="{feedback_label:true, 'feedback_label-checkbox': true, errors: errors.your_policy_status }">
            <input
              class="feedback_checkbox visually-hidden"
              type="checkbox"
              name="privacy-policy"
              v-model="dataForm.your_policy"
            />
            <span class="feedback-checkbox-custom"></span>
            <span class="feedback_label-checkbox-text">{{
              errors.your_policy_status
                ? errors.your_policy
                : "Я согласен с персональной обработкой данных"
            }}</span>
          </label>
        </p>
        <button class="bnt feedback_bnt" v-on:click="submitForm">
          <span class="bnt-text bnt-text--one">{{ content.button }}</span>
          <span class="bnt-text bnt-text--two">{{ content.button }}</span>
        </button>
      </form>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      content: this.$store.state.data.contentResponse.form,
      validation: true,
      errors: {
        your_phone: "* Требуется указать телефон",
        your_phone_status: false,
        your_policy: "Требуется согласие на обработку данных",
        your_policy_status: false,
      },
      dataForm: {
        id: 220,
        your_name: "",
        your_phone: "",
        your_policy: true,
      },
    };
  },
  methods: {
    submitForm(event) {
      event.preventDefault();

      this.validation = true;
      this.errors.your_phone_status = false;
      this.errors.your_policy_status = false;
      
      if (this.dataForm.your_phone.length != 18) {
        this.validation = false;
        this.errors.your_phone_status = true;
      }

      if (this.dataForm.your_policy == "") {
        this.validation = false;
        this.errors.your_policy_status = true;
      }

      if (this.validation) {
        this.$metrika.reachGoal('kontaktnaya-forma-portal')
        this.$store.dispatch("submitForm", this.dataForm);
      }
    },
  },
};
</script>
<style scoped>
.feedback {
  padding: 120px 0;
}

.feedback_title {
  margin: 0;
  margin-bottom: 10px;
  font-size: 36px;
  font-weight: 700px;
  text-transform: uppercase;
  color: #323232;
}

.feedback_desc {
  margin: 0;
  margin-bottom: 30px;
  font-size: 20px;
  line-height: 1.5;
  text-transform: lowercase;
}

.feedback_form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.feedback_elem {
  margin: 0;
  margin-right: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.feedback_elem:last-child {
  margin-right: 0;
}

.feedback_label {
  margin-left: 15px;
  margin-bottom: 10px;
  font-size: 14px;
}

.feedback_input {
  width: 330px;
  padding: 12px;
  border-radius: 50px;
  border: 1px solid #eeeeee;
  background-color: transparent;
}

.feedback_input:focus {
  outline: none;
  border: 1px solid #c2c2c2;
}

.feedback_label-checkbox {
  margin: 0;
  margin-bottom: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.feedback-checkbox-custom {
  width: 20px;
  height: 20px;
  border: 1px solid #2b2a62;
  border-radius: 4px;
  margin-right: 17px;
  display: block;
  position: relative;
  -webkit-transition: background-color 0.5s ease;
  -o-transition: background-color 0.5s ease;
  transition: background-color 0.5s ease;
}

.feedback-checkbox-custom::before {
  content: "";
  width: 0;
  height: 12px;
  position: absolute;
  top: calc(50% - 6px);
  left: calc(50% - 6px);
  background-image: url(@/assets/img/checkbox.svg);
  -webkit-transition: width 0.5s ease;
  -o-transition: width 0.5s ease;
  transition: width 0.5s ease;
}

.feedback_checkbox:checked ~ .feedback-checkbox-custom {
  background-color: #2b2a62;
}

.feedback_checkbox:checked ~ .feedback-checkbox-custom::before {
  width: 12px;
}

.feedback_label-checkbox-text {
  font-size: 14px;
  line-height: 1.5;
}

.errors {
  color: #f37777;
}

@media screen and (max-width: 1599px) {
  .feedback {
    padding: 100px 0;
  }

  .feedback_title {
    font-size: 28px;
  }

  .feedback_desc {
    font-size: 16px;
  }

  .feedback_label {
    font-size: 12px;
  }

  .feedback_input {
    width: 246px;
  }

  .feedback-checkbox-custom {
    width: 18px;
    height: 18px;
    margin-right: 10px;
  }

  .feedback-checkbox-custom::before {
    height: 10px;
    top: calc(50% - 5px);
    left: calc(50% - 5px);
    background-image: url(@/assets/img/checkbox_1599.svg);
    background-repeat: no-repeat;
  }

  .feedback_checkbox:checked ~ .feedback-checkbox-custom::before {
    width: 10px;
  }

  .feedback_label-checkbox-text {
    font-size: 12px;
  }
}

@media screen and (max-width: 1199px) {
  .feedback {
    padding: 70px 0;
  }

  .feedback_title {
    font-size: 24px;
    margin-bottom: 5px;
  }

  .feedback_desc {
    font-size: 14px;
    margin-bottom: 30px;
  }

  .feedback_label {
    font-size: 10px;
  }

  .feedback_label-checkbox-text {
    margin-top: 2px;
    font-size: 10px;
  }

  .feedback_bnt {
    margin-left: auto;
  }
}

@media screen and (max-width: 991px) {
  .feedback {
    padding: 70px 0;
  }

  .feedback_title {
    margin-bottom: 5px;
    font-size: 22px;
  }

  .feedback_desc {
    font-size: 14px;
    margin-bottom: 20px;
  }

  .feedback_form {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
  }

  .feedback_elem {
    margin: 0;
    margin-bottom: 20px;
  }

  .feedback_label {
    margin-left: 15px;
    margin-bottom: 5px;
    font-size: 10px;
  }

  .feedback_input {
    width: 100%;
  }

  .feedback_elem-checkbox {
    margin: 5px 0 25px 0;
  }

  .feedback_label-checkbox {
    margin: 0;
  }

  .feedback_label-checkbox-text {
    font-size: 10px;
  }

  .feedback_bnt {
    margin-left: 0;
    -ms-flex-item-align: start;
    align-self: flex-start;
  }
}

@media screen and (max-width: 767px) {
  .feedback {
    padding: 60px 0;
  }

  .feedback_title {
    margin-bottom: 10px;
    font-size: 20px;
  }

  .feedback_desc {
    font-size: 12px;
    margin-bottom: 20px;
  }

  .feedback_label {
    font-size: 10px;
  }

  .feedback_input {
    width: 100%;
  }

  .feedback_elem-checkbox {
    margin: 5px 0 25px 0;
  }

  .feedback_label-checkbox {
    margin: 0;
  }

  .feedback_label-checkbox-text {
    font-size: 10px;
  }

  .feedback_bnt {
    margin-left: 0;
    -ms-flex-item-align: start;
    align-self: flex-start;
  }
}
</style>