<template>
  <div class="popup" @click.self="closePopup">
    <div class="popup_wrapper">
      <span class="popup_close" v-on:click="closePopup"></span>
      <div class="popup_title">{{ popup.title }}</div>
      <form class="popup_form" action="#">
        <p class="popup_elem">
          <label class="popup_label" for="popup_name">Имя</label>
          <input
            class="popup_input"
            type="text"
            name="popup_name"
            id="popup_name"
            v-model="dataForm.your_name"
          />
        </p>
        <p class="popup_elem">
          <label v-bind:class="{ popup_label: true, errors: errors.your_phone_status }" for="popup_phone">{{
            errors.your_phone_status ? errors.your_phone : "* Телефон"
          }}</label>
          <input
            class="popup_input"
            type="tel"
            name="popup_phone"
            id="popup_phone"
            v-maskForm
            v-model="dataForm.your_phone"
          />
        </p>
        <p class="popup_elem popup_elem-checkbox">
          <label v-bind:class="{feedback_label:true, 'popup_label-checkbox': true, errors: errors.your_policy_status }">
            <input
              class="popup_checkbox visually-hidden"
              type="checkbox"
              name="privacy-policy"
              v-model="dataForm.your_policy"
            />
            <span class="popup-checkbox-custom"></span>
            <span class="popup_label-checkbox-text"
              >{{
              errors.your_policy_status
                ? errors.your_policy
                : "Я согласен с персональной обработкой данных"
            }}</span
            >
          </label>
        </p>
        <button class="bnt popup_bnt" v-on:click="submitForm">
          <span class="bnt-text bnt-text--one">Заказать</span>
          <span class="bnt-text bnt-text--two">Заказать</span>
        </button>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  data: function () {
    return {
      popup: this.$store.state.popupForm,
      validation: true,
      errors: {
        your_phone: "* Требуется указать телефон",
        your_phone_status: false,
        your_policy: "Требуется согласие на обработку данных",
        your_policy_status: false,
      },
      dataForm: {
        id: this.$store.state.popupForm.id,
        your_name: "",
        your_phone: "",
        your_policy: true,
      },
    };
  },
  methods: {
    closePopup() {
      this.$store.commit("conditionPopup", { status: false, title: null, id: null });
    },
    submitForm(event) {
      event.preventDefault();

      this.validation = true;
      this.errors.your_phone_status = false;
      this.errors.your_policy_status = false;
      
      if (this.dataForm.your_phone.length != 18) {
        this.validation = false;
        this.errors.your_phone_status = true;
      }

      if (this.dataForm.your_policy == "") {
        this.validation = false;
        this.errors.your_policy_status = true;
      }

      if (this.validation) {
        this.$metrika.reachGoal(this.popup.goal)
        this.$store.dispatch("submitForm", this.dataForm);
      }
    },
  },
};
</script>

<style scoped>
.popup {
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 99999999999;
  background-color: rgb(0, 0, 0, 0.85);
}

.popup_wrapper {
  width: 510px;
  padding: 100px 80px;
  border-radius: 10px;
  position: relative;
  background-color: #ffffff;
}

.popup_close {
  width: 25px;
  height: 25px;
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
  -webkit-transition: -webkit-transform 0.5s ease-in;
  transition: -webkit-transform 0.5s ease-in;
  -o-transition: transform 0.5s ease-in;
  transition: transform 0.5s ease-in;
  transition: transform 0.5s ease-in, -webkit-transform 0.5s ease-in;
}

.popup_close:hover {
  -webkit-transform: rotateZ(360deg);
  -ms-transform: rotate(360deg);
  transform: rotateZ(360deg);
}

.popup_close::before,
.popup_close::after {
  content: "";
  width: 25px;
  height: 2px;
  position: absolute;
  top: 11px;
  background-color: #dbdbdb;
}

.popup_close::before {
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.popup_close::after {
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.popup_title {
  margin: 0;
  margin-bottom: 20px;
  font-size: 36px;
  font-weight: 700;
  text-transform: uppercase;
  color: #323232;
  user-select: none;
}

.popup_elem {
  margin: 0;
  margin-bottom: 25px;
}

.popup_label {
  display: block;
  margin-left: 15px;
  margin-bottom: 10px;
  font-size: 14px;
  line-height: 1.5;
}

.popup_input {
  display: block;
  width: 100%;
  padding: 11px;
  border: 1px solid #dddddd;
  border-radius: 50px;
  background-color: #ffffff;
  transition: background-color 0.5s ease;
}

.popup_input:focus {
  outline: none;
  background-color: #f9f9f9;
}

.popup_label-checkbox {
  margin: 0;
  margin-bottom: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
}

.popup-checkbox-custom {
  width: 20px;
  height: 20px;
  border: 1px solid #2b2a62;
  border-radius: 4px;
  margin-right: 17px;
  display: block;
  position: relative;
  -webkit-transition: background-color 0.5s ease;
  -o-transition: background-color 0.5s ease;
  transition: background-color 0.5s ease;
}

.popup-checkbox-custom::before {
  content: "";
  width: 0;
  height: 12px;
  position: absolute;
  top: calc(50% - 6px);
  left: calc(50% - 6px);
  background-image: url(@/assets/img/checkbox.svg);
  background-repeat: no-repeat;
  -webkit-transition: width 0.5s ease;
  -o-transition: width 0.5s ease;
  transition: width 0.5s ease;
}

.popup_checkbox:checked ~ .popup-checkbox-custom {
  background-color: #2b2a62;
}

.popup_checkbox:checked ~ .popup-checkbox-custom::before {
  width: 12px;
}

.popup_label-checkbox-text {
  padding-top: 3px;
  font-size: 14px;
  line-height: 1.5;
}

.errors {
  color: #f37777;
}

@media screen and (max-width: 1599px) {
  .popup_wrapper {
    width: 450px;
    padding: 90px 70px;
  }

  .popup_title {
    font-size: 32px;
  }

  .popup_elem {
    margin-bottom: 20px;
  }

  .popup_label {
    font-size: 12px;
  }

  .popup_label-checkbox-text {
    font-size: 12px;
  }
}

@media screen and (max-width: 1199px) {
  .popup_wrapper {
    width: 430px;
    padding: 80px 60px;
  }

  .popup_title {
    font-size: 28px;
  }

  .popup-checkbox-custom {
    width: 18px;
    height: 18px;
    margin-right: 10px;
  }

  .popup-checkbox-custom::before {
    height: 10px;
    top: calc(50% - 5px);
    left: calc(50% - 5px);
    background-image: url(@/assets/img/checkbox_1599.svg);
  }

  .popup_checkbox:checked ~ .popup-checkbox-custom::before {
    width: 10px;
  }

  .popup_label-checkbox-text {
    padding-top: 0px;
    font-size: 12px;
  }
}

@media screen and (max-width: 991px) {
  .popup_wrapper {
    width: 400px;
    padding: 70px 50px;
  }

  .popup_title {
    font-size: 26px;
  }
}

@media screen and (max-width: 767px) {
  .popup_wrapper {
    width: 300px;
    padding: 50px 20px;
  }

  .popup_title {
    font-size: 20px;
  }

  .popup_label-checkbox-text {
    margin-top: 3px;
    font-size: 10px;
  }
}
</style>