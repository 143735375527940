import { createStore } from 'vuex'
import axios from "axios";

export default createStore({
  state() {
    return {
      preloader: true,
      data: null,
      mobileMenu: false,
      popupForm: {
        status: false,
        title: null,
        id: null,
        goal: null
      },
      popupInfo: {
        status: false,
        message: ""
      }
    }
  },
  mutations: {
    preloaderOff(state) {
      state.preloader = false;
    },
    updateData(state, preload) {
      state.data = preload;
    },
    conditionMenu(state) {
      state.mobileMenu = !state.mobileMenu;
    },
    conditionPopup(state, preload) {
      state.popupForm = {
        status: preload.status,
        title: preload.title,
        id: preload.id,
        goal: preload.goal
      }
    },
    conditionPopupInfo(state, preload) {
      state.popupInfo = {
        status: preload.status,
        message: preload.message
      }
    }
  },
  actions: {
    async loadingData(context) {
      const [menuResponse, contentResponse, mediaResponse] = await Promise.all([
        axios.get(`https://api-portal.alukont.ru/wp-json/wp-api-menus/v2/menus/3`),
        axios.get(`https://api-portal.alukont.ru/wp-json/wp/v2/pages/20`),
        axios.get(`https://api-portal.alukont.ru/wp-json/wp/v2/media?parent=20&per_page=100`)
      ]);

      let transformMedia = {};
      mediaResponse.data.forEach((item) => transformMedia[item.id] = item.media_details.sizes);

      context.commit('updateData', {
        menuResponse: menuResponse.data.items,
        contentResponse: contentResponse.data.acf,
        mediaResponse: transformMedia
      });

      setTimeout(() => context.commit('preloaderOff'), 1500)
    },
    async submitForm(context, payload) {
      let bodyRequest = new FormData();

      for (let key in payload) {
        if (key != 'id') {
          bodyRequest.append(key, payload[key]);
        }
      }

      axios({
        method: "post",
        url: `https://api-portal.alukont.ru/wp-json/contact-form-7/v1/contact-forms/${payload.id}/feedback`,
        data: bodyRequest,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then(function (response) {
          if (context.state.popupForm.status) {
            context.commit('conditionPopup', {
              status: false,
              title: "",
              id: null
            })
          }

          context.commit('conditionPopupInfo', {
            status: true,
            message: response.data.message
          });
          setTimeout(() => {
            context.commit('conditionPopupInfo', {
              status: false,
              message: ""
            });
          }, 2000)
        })
        .catch(function (error) {
          console.log(error);
        })
    }
  }
})