import { createApp } from 'vue'
import VueYandexMetrika from 'vue3-yandex-metrika'
import App from '@/App.vue'
import Store from '@/store/index.js'
import scrollMenu from '@/directives/scrollMenu.js'
import maskForm from '@/directives/maskForm.js'
import VueScrollTo from 'vue-scrollto'
import '@/assets/style/normalize.css'
import '@/assets/style/main.css'
import 'aos/dist/aos.css'

createApp(App)
  .use(Store)
  .use(VueScrollTo, {
    container: "body",
    duration: 500,
  }).use(VueYandexMetrika, {
    id: 35988050,
    env: 'production',
    options: {
      clickmap:true,
      trackLinks:true,
      accurateTrackBounce:true,
      webvisor:true
    }
  })
  .directive('scroll', scrollMenu)
  .directive('maskForm', maskForm)
  .mount('#app')